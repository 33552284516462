import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './Registration.module.scss';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { URLRoutes } from "../../enums/Routes";
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ISnackbar } from '../../interface/Snackbar';
import SnackbarAlert from '../../components/SnackbarAlert/SnackbarAlert';
import { URL } from "../../enums/Url";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface IRegisterData {
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    terms: boolean
}

const Registration = () => {
    const history = useHistory();
    const [registerButton, setRegisterButton] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const [registerData, setRegisterData] = useState<IRegisterData>({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        terms: false
    })

    const [errorInputEmail, setErrorInputEmail] = useState(false);
    const [errorInputPassword, setErrorInputPassword] = useState(false);
    const [errorInputFirstName, setErrorInputFirstName] = useState(false);
    const [errorInputLastName, setErrorInputLastName] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [errorMsgEmail, setErrorMsgEmail] = useState<string | undefined>('');
    const [errorMsgPassword, setErrorMsgPassword] = useState<string | undefined>('');
    const [errorMsgFirstName, setErrorMsgFirstName] = useState<string | undefined>('');
    const [errorMsgLastName, setErrorMsgLastName] = useState<string | undefined>('');

    const [checked, setChecked] = useState(false);


    const handleClick = () => {
        setShowPassword(prev => !prev);
    }

    const handleChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target
        switch (name) {
            case 'email': {
                setErrorMsgEmail(helperValidateEmail(value))
                setErrorInputEmail(helperValidateEmail(value) ? true : false);
                break;
            }
            case 'password': {
                setErrorMsgPassword(helperValidatePassword(value))
                setErrorInputPassword(helperValidatePassword(value) ? true : false);
                break;
            }
            case 'first_name': {
                setErrorMsgFirstName(helperValidateName(value))
                setErrorInputFirstName(helperValidateName(value) ? true : false);
                break;
            }
            case 'last_name': {
                setErrorMsgLastName(helperValidateName(value))
                setErrorInputLastName(helperValidateName(value) ? true : false);
                break;
            }
        }

        setRegisterData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmitClick = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        let errorText;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(registerData)
        };

        try {

            const response = await fetch(URL.Registration, options);

            if (response.status === 200) {
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Profile created successfully!'
                })
                setTimeout(() => {
                    history.push(`${URLRoutes.Login}`)
                }, 5000);
            } else if (response.status === 404) {
                errorText = await response.text();
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: errorText ? errorText : 'Error'
                })
                snackbarAutoHide();
            }
        } catch (ex) {
            console.log('ex', ex);
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        }
    }

    const helperValidateName = (inputName: string) => {
        if (inputName.replace(/[a-zA-Z0-9_ ]/g, '').length > 0) return 'Only letters and numbers!';
    }

    const helperValidateEmail = (email: string) => {
        let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length > 0 && !regEmail.test(email)) {
            return 'Invalid Email Address';
        }
    }
    const helperValidatePassword = (password: string) => {
        let regPass = /(?=.*[a-z])(?=.*[A-Z])/
        if (password.length > 0 && !regPass.test(password)) {
            return 'Password should have more than 8 characters, one uppercase and one lowercase character!'
        }
    }

    useEffect(() => {
        if (!errorMsgEmail && !errorMsgPassword && !errorMsgFirstName && !errorMsgLastName && registerData.first_name.length > 2 && registerData.last_name.length > 2 && registerData.email.length > 0 && registerData.password.length > 0 && checked) {
            setRegisterButton(true);
        } else {
            setRegisterButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerData]);

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setRegisterData(prevState => ({
            ...prevState,
            terms: event.target.checked
        }))
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 3000);
    }

    return <div className={styles.registration_page_wrapper}>
        <div className={styles.registration_page_left_section}>
            <div className={styles.header_title}>
                <span className="icon icon_beoexport_medium"></span>
            </div>
            <div className={styles.title_section}>
                <div className={styles.greeting_msg}>A few clicks from creating your account.</div>
                <div className={styles.greeting_title}>SIGN UP FOR FREE</div>
                <hr />
                <div className={styles.secondary_text}>
                </div>
            </div>
        </div>
        <div className={styles.registration_page_right_section}>
            <div className={styles.go_back_section_login}>
                <ArrowBackIosIcon className={styles.icon_back} />
                <Link to={URLRoutes.Login} className={styles.back_to_login}>Login</Link>
            </div>
            <h1 className={styles.registration_title}>REGISTER</h1>
            <h4 className={styles.registration_subtitle}>Enter your personal details and join Beo-Export</h4>

            <form noValidate autoComplete="off">
                <TextField autoComplete='off' name='email' variant="outlined" required placeholder={"Email"} type='text' helperText={errorMsgEmail} error={errorInputEmail} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 90 }} />
                <TextField autoComplete='off' name='password' variant="outlined" placeholder={'Password'} type={showPassword ? 'text' : 'password'} className={styles.registration_password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClick}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    helperText={errorMsgPassword} error={errorInputPassword} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 64 }} />
                <TextField autoComplete='off' name='first_name' variant="outlined" placeholder={'First Name'} type='text' helperText={errorMsgFirstName} error={errorInputFirstName} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 64 }} />
                <TextField autoComplete='off' name='last_name' variant="outlined" placeholder={'Last Name'} type='text' helperText={errorMsgLastName} error={errorInputLastName} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 90 }} />
                <div className={styles.links_section}>
                    <Link to={URLRoutes.ForgetPassword} className={styles.privacy_policy}>{'Forgot Password'}</Link>
                </div>
                <div className={styles.register_section}>
                    <FormControlLabel className={styles.check_box} control={<Checkbox checked={checked} onChange={handleCheckBoxChange} name="terms" />} label="" />
                    <div className={styles.title_form}>I agree to the Beo-Export <span onClick={() => window.open("https://beoexport.com.au/wp-content/uploads/general/BEO-Export_Terms_and_Condtions_Sep_2014_Version_2_2.pdf")} className={styles.links}>Terms and Conditions</span>,<span onClick={() => window.open("https://beoexport.com.au/wp-content/uploads/general/BeoExport_MT_FSGPDS_September2015_WEB.pdf")} className={styles.links}> FSG and PDS</span> and <span onClick={() => window.open("https://beoexport.com.au/wp-content/uploads/general/BEOE001_11232_020_Privacy-Policy_2018-07-11.pdf")} className={styles.links}>Privacy Policy</span>.</div>
                </div>
                <button type='button' className={`${styles.button} ${!registerButton ? styles.disabled : ''}`} onClick={handleSubmitClick}>REGISTER</button>
            </form>
        </div>
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>
}

export default Registration;