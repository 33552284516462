import { BrowserRouter, Switch, Route } from "react-router-dom";
import { URLRoutes } from "../../enums/Routes";
import Login from "../../containers/Login/Login";
import Registration from "../../containers/Registration/Registration";
import MoneyTransfer from "../../containers/TransferMoney/MoneyTransfer";
import TransactionListing from "../../containers/TransactionList/TransactionListing";
import AboutUs from "../../containers/AboutUs/AboutUs";
import Layout from "../Layout/Layout";
import Profile from "../../containers/Profile/Profile";
import Admins from "../../containers/Admin/Admins/Admins";
import Users from "../../containers/Admin/Users/Users";
import PaymentOptions from "../../containers/Admin/PaymentOptions/PaymentOptions";
import PayoutOptions from "../../containers/Admin/PayoutOptions/PayoutOptions";
import PurposeOfTransfer from "../../containers/Admin/PurposeOfTransfer/PurposeOfTransfer";
import CommissionFee from "../../containers/Admin/CommissionFee/CommissionFee";
import ExchangeRate from "../../containers/Admin/ExchangeRate/ExchangeRate";
import Transactions from "../../containers/Admin/Transactions/Transactions";
import SecureRoute from "../SecureRoute/SecureRoute";
import UserView from "../../containers/Admin/Users/UserView/UserView";
import ForgetPassword from "../../containers/ForgetPassword/ForgetPassword";
import EmptyPage from "../../containers/EmptyPage/EmptyPage";
import TransactionViewAdmin from "../../containers/Admin/Transactions/TransactionViewAdmin/TransactionViewAdmin";
import TransactionViewClient from "../../containers/TransactionList/TransactionView/TransactionView";

const Routing = () => {
    return <BrowserRouter>
        <Switch>
            <Route exact path={[URLRoutes.Empty, URLRoutes.Login]}>
                <Login />
            </Route>
            <Route exact path={URLRoutes.Registration}>
                <Registration />
            </Route>
            <Route exact path={URLRoutes.ForgetPassword}>
                <ForgetPassword />
            </Route>
            <Layout>
                <SecureRoute exact path={URLRoutes.MoneyTransfer} role='1'>
                    <MoneyTransfer />
                </SecureRoute>
                <SecureRoute exact path={URLRoutes.TransactionListing} role='1'>
                    <TransactionListing />
                </SecureRoute>
                <SecureRoute exact path={URLRoutes.TransactionItem} role='1'>
                    <TransactionViewClient />
                </SecureRoute>
                <SecureRoute exact path={URLRoutes.AboutUs} role='1'>
                    <AboutUs />
                </SecureRoute>
                <SecureRoute exact path={URLRoutes.Profile} role='1'>
                    <Profile />
                </SecureRoute>
                <SecureRoute exact path={URLRoutes.SetProfileData} role='1'>
                    <EmptyPage />
                </SecureRoute>

                {/* Admin pages */}
                <SecureRoute exact path={URLRoutes.Admins} role="3">
                    <Admins />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.Users} role="3">
                    <Users />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.PaymentOptions} role="3">
                    <PaymentOptions />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.PayoutOptions} role="3">
                    <PayoutOptions />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.PurposeOfTransfer} role="3">
                    <PurposeOfTransfer />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.CommissionFee} role="3">
                    <CommissionFee />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.ExchangeRate} role="3">
                    <ExchangeRate />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.Transactions} role="3">
                    <Transactions />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.UserItem} role="3">
                    <UserView />
                </SecureRoute>

                <SecureRoute exact path={URLRoutes.TransactionView} role="3">
                    <TransactionViewAdmin />
                </SecureRoute>

            </Layout>
        </Switch>
    </BrowserRouter>

}

export default Routing;