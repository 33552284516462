import { Button, Modal, Popover } from '@material-ui/core';
import moment from 'moment';
import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import AdminUsersFilter from '../../../components/Filters/AdminUsersFilter/AdminUsersFilter';
import SnackbarAlert from '../../../components/SnackbarAlert/SnackbarAlert';
import AdminUsersTable from '../../../components/Tables/AdminUsersTable/AdminUsersTable';
import { LocalStorage } from '../../../enums/LocalStorage';
import { ISnackbar } from '../../../interface/Snackbar';
import { IProfilData } from '../../../interface/TransferMoney';
import EditUser from './EditUser/EditUser';
import styles from './Users.module.scss';
import AdminService from '../../../services/Admin';

const Users = () => {
    const location = useLocation();
    const queryString = require('query-string');
    const tableUrlParams = ['page', 'order_by'];
    const [filterDialog, setFilterDialog] = useState<HTMLButtonElement>();
    const [filterParams, setFilterParams] = useState<any>(queryString.parse(location.search) ? Object.fromEntries(Object.entries(queryString.parse(location.search)).filter(([k, _]) => !tableUrlParams.includes(k))) : {});
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [userData, setUserData] = useState<any>();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const childRef = useRef<any>();

    const isEmptyObject = (object = {}) => {
        return Object.keys(object).length === 0;
    }

    const applyFilters = (parameters: any | undefined) => {
        setFilterParams(parameters);
        setFilterDialog(undefined);
    }

    const editOption = (option: any) => {
        setUserData(option);
        handleOpen();
    }

    const saveUserData = (parameters: IProfilData) => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const userId = JSON.parse(localStorageDataAuth ? localStorageDataAuth : '').id;
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null

        parameters.DATUM_ISTEKA_ID = moment(parameters.DATUM_ISTEKA_ID).format('YYYY-MM-DD');

        AdminService.updateUserAdminSide(userId, token, parameters,).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'User profile updated.'
            })
            childRef?.current.getRefresData();
            setOpen(false);
            snackbarAutoHide();
        }).catch(error => {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }


    return <div className={styles.admin_users_wrapper}>
        <div className={styles.header_section}>
            <div className={styles.title}>Users</div>
            <div className={styles.subtitle}>List of registered users.</div>
        </div>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><EditUser close={() => handleClose()} profileData={userData} saveNewProfilData={saveUserData} /></div>
        </Modal>
        <div className={styles.admin_users_filter}>
            <Button className={`${styles.filter_circle} ${!isEmptyObject(filterParams) ? styles.active_filter : ''}`} onClick={(e) => setFilterDialog(e.currentTarget)}><span className="icon icon_filter"></span></Button>
            <Popover id='admin_users_filter' open={Boolean(filterDialog)} anchorEl={filterDialog} onClose={() => setFilterDialog(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <AdminUsersFilter appliedFilters={filterParams} applyFilters={applyFilters} close={() => setFilterDialog(undefined)} datasetFilter={true} />
            </Popover>
        </div>
        <AdminUsersTable editOption={editOption} filterParameters={filterParams} ref={childRef} />
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>
}

export default Users;