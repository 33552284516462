import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './AdminUsersFilter.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { IFilters, IFilterParameters } from '../../../interface/TransferMoney';
import { TextField } from '@material-ui/core';
import { KeyboardKeys } from '../../../enums/Filters';
import RangeDatepicker from '../../RangeDatepicker/RangeDatepicker';
import { RangeWithKey } from 'react-date-range';
import moment from 'moment';

const AdminUsersFilter = (props: IFilters) => {
    const [filters, setFilters] = useState<IFilterParameters>();
    const [applyButton, setApplyButton] = useState<boolean>(false);
    const [clearButton, setClearButton] = useState<boolean>(false);

    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof filters;

        setFilters(prevState => ({
            ...prevState,
            [name]: value
        }));

        setApplyButton(true);
        setClearButton(true);
    }

    useEffect(() => {
        if (props.appliedFilters && !isEmptyObject(props.appliedFilters)) {
            setFilters(props.appliedFilters);
            setClearButton(true);
        }

    }, [props.appliedFilters]);

    const isEmptyObject = (object?: IFilterParameters) => {
        if (object) {
            Object.keys(object).forEach(key => object[key as keyof object] === '' ? delete object[key as keyof object] : {});
            return Object.values(object).length === 0;
        }

    }

    const clear = () => {
        setFilters({ memberNumber: '', fullName: '', email: '', dateOfBirth: '', dateOfregistration: '' });
        if (props.applyFilters) {
            props.applyFilters({});
            setClearButton(false);
        }
    }

    const applyFilters = () => {
        if (props.applyFilters) {
            props.applyFilters(filters);
            setApplyButton(false);
        }
    }

    const keyboardEvents = (event: { key: string; }) => {
        if (!applyButton) { return; }
        if (event.key === KeyboardKeys.Enter) {
            applyFilters();
        }
    }

    const onChangeRangeFilters = (range: RangeWithKey) => {
        const format = 'YYYY-MM-DD';
        const startDate = moment(range.startDate).format(format);
        const endDate = moment(range.endDate).add(1, 'days').format(format);
        return startDate + '~`' + endDate;
    }

    const onChangeRange = (range: RangeWithKey, name: string) => {
        setFilters(prevState => ({
            ...prevState,
            [name]: onChangeRangeFilters(range)
        }));
        setApplyButton(true);
        setClearButton(true);
    }

    return <div tabIndex={0} className={styles.admin_user_filter_container} onKeyPress={keyboardEvents}>
        <CloseIcon onClick={props.close} className={styles.close_icon} />
        <div className={styles.title_container}>
            <span className={`${styles.filter_icon} icon icon_filter`}></span>
            <div>
                <div className={styles.title}>Users Filter</div>
                <div className={styles.subtitle}>Use the below filters to find users.</div>
            </div>
        </div>
        <div className={styles.autocomplete_container}>
            <div>
                <div className={styles.filter_label}>Member number</div>
                <TextField autoComplete='off' name='memberNumber' value={filters?.memberNumber || ''} className={styles.filter_field} placeholder={'Member number'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
            </div>
            <div>
                <div className={styles.filter_label}>Full name</div>
                <TextField autoComplete='off' name='fullName' value={filters?.fullName || ''} className={styles.filter_field} placeholder={'Full name'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
            </div>
            <div>
                <div className={styles.filter_label}>Email</div>
                <TextField autoComplete='off' name='email' value={filters?.email || ''} className={styles.filter_field} placeholder={'Email'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
            </div>
        </div>
        <div className={styles.autocomplete_container}>
            <div>
                <div className={styles.filter_label}>Date Of Birth</div>
                {/* <TextField autoComplete='off' name='date_of_birth' className={styles.filter_field} placeholder={'Date Of Birth'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} /> */}
                <RangeDatepicker value={filters?.dateOfBirth || ''} placeholder={'Date Of Birth'} change={(value: RangeWithKey) => onChangeRange(value, 'dateOfBirth')} />
            </div>
            <div>
                <div className={styles.filter_label}>Date Of Registration</div>
                {/* <TextField name='date_of_registration' className={styles.filter_field} placeholder={'Date Of Registration'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} /> */}
                <RangeDatepicker value={filters?.dateOfregistration || ''} placeholder={'Date Of Registration'} change={(value: RangeWithKey) => onChangeRange(value, 'dateOfregistration')} />
            </div>
        </div>
        <div className={styles.buttons_container}>
            <button className={`${styles.clear_button} ${!clearButton ? styles.disabled : ''}`} onClick={clear}>{'CLEAR'}</button>
            <button className={`${styles.apply_button} ${!applyButton ? styles.disabled : ''}`} onClick={applyFilters}>{'APPLY'}</button>
        </div>
    </div>
}

export default AdminUsersFilter;