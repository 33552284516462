import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './TransactionListingFilter.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { IFilters, IFilterParameters } from '../../../interface/TransferMoney';
import { TextField } from '@material-ui/core';
import { KeyboardKeys } from '../../../enums/Filters';
import RangeDatepicker from '../../RangeDatepicker/RangeDatepicker';
import { RangeWithKey } from 'react-date-range';
import moment from 'moment';

const TransactionListingFilter = (props: IFilters) => {
    const [filters, setFilters] = useState<IFilterParameters>();
    const [applyButton, setApplyButton] = useState<boolean>(false);
    const [clearButton, setClearButton] = useState<boolean>(false);

    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof filters;

        setFilters(prevState => ({
            ...prevState,
            [name]: value
        }));

        setApplyButton(true);
        setClearButton(true);
    }

    useEffect(() => {
        if (props.appliedFilters && !isEmptyObject(props.appliedFilters)) {
            setFilters(props.appliedFilters);
            setClearButton(true);
        }

    }, [props.appliedFilters]);

    const isEmptyObject = (object?: IFilterParameters) => {
        if (object) {
            Object.keys(object).forEach(key => object[key as keyof object] === '' ? delete object[key as keyof object] : {});
            return Object.values(object).length === 0;
        }

    }

    const clear = () => {
        setFilters({ id: '', name: '', createdOn: '' });
        if (props.applyFilters) {
            props.applyFilters({});
            setClearButton(false);
        }
    }

    const applyFilters = () => {
        if (props.applyFilters) {
            props.applyFilters(filters);
            setApplyButton(false);
        }
    }

    const keyboardEvents = (event: { key: string; }) => {
        if (!applyButton) { return; }
        if (event.key === KeyboardKeys.Enter) {
            applyFilters();
        }
    }

    const onChangeRangeFilters = (range: RangeWithKey) => {
        const format = 'YYYY-MM-DD';
        const startDate = moment(range.startDate).format(format);
        const endDate = moment(range.endDate).add(1, 'days').format(format);
        return startDate + '~`' + endDate;
    }

    const onChangeRange = (range: RangeWithKey, name: string) => {
        setFilters(prevState => ({
            ...prevState,
            [name]: onChangeRangeFilters(range)
        }));
        setApplyButton(true);
        setClearButton(true);
    }

    return <div tabIndex={0} className={styles.transaction_listing_filter_container} onKeyPress={keyboardEvents}>
        <CloseIcon onClick={props.close} className={styles.close_icon} />
        <div className={styles.title_container}>
            <span className={`${styles.filter_icon} icon icon_filter`}></span>
            <div>
                <div className={styles.title}>Transactions Filter</div>
                <div className={styles.subtitle}>Use the below filters to find transactions.</div>
            </div>
        </div>
        <div className={styles.autocomplete_container}>
            <div>
                <div className={styles.filter_label}>Recipient</div>
                <TextField autoComplete='off' name='recipient' value={filters?.recipient || ''} className={styles.filter_field} placeholder={'Recipient'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
            </div>
            <div>
                <div className={styles.filter_label}>Amount</div>
                <TextField autoComplete='off' name='amount' value={filters?.amount || ''} className={styles.filter_field} placeholder={'Amount'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
            </div>
            <div>
                <div className={styles.filter_label}>Transfer</div>
                <TextField autoComplete='off' name='transfer' value={filters?.transfer || ''} className={styles.filter_field} placeholder={'Transfer'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
            </div>
        </div>
        <div className={styles.autocomplete_container}>
            <div>
                <div className={styles.filter_label}>Date Of Payout</div>
                <RangeDatepicker value={filters?.date_of_payout || ''} placeholder={'Date Of Payout'} change={(value: RangeWithKey) => onChangeRange(value, 'date_of_payout')} />
            </div>
            <div>
                <div className={styles.filter_label}>Date Of Payment</div>
                <RangeDatepicker value={filters?.date_of_payment || ''} placeholder={'Date Of Payment'} change={(value: RangeWithKey) => onChangeRange(value, 'date_of_payment')} />
            </div>
        </div>
        <div className={styles.buttons_container}>
            <button className={`${styles.clear_button} ${!clearButton ? styles.disabled : ''}`} onClick={clear}>{'CLEAR'}</button>
            <button className={`${styles.apply_button} ${!applyButton ? styles.disabled : ''}`} onClick={applyFilters}>{'APPLY'}</button>
        </div>
    </div>
}

export default TransactionListingFilter;