import React, { ChangeEvent, useState, MouseEvent, useEffect } from 'react'
import styles from './ForgetPassword.module.scss';
import { TextField } from '@material-ui/core';
import { URLRoutes } from "../../enums/Routes";
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { URL } from "../../enums/Url";
import { ISnackbar } from '../../interface/Snackbar';
import SnackbarAlert from '../../components/SnackbarAlert/SnackbarAlert';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [errorInputEmail, setErrorInputEmail] = useState(false);
    const [errorMsgEmail, setErrorMsgEmail] = useState<string | undefined>('');
    const [registerButton, setRegisterButton] = useState<boolean>(false);
    const history = useHistory();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });

    const handleChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target
        switch (name) {
            case 'email': {
                setErrorMsgEmail(helperValidateEmail(value))
                setErrorInputEmail(helperValidateEmail(value) ? true : false);
                break;
            }
        }
        setEmail(value)
    }

    const resetPass = async (e: MouseEvent) => {
        e.preventDefault();

        let errorText;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(email)
        };

        try {

            const response = await fetch(URL.ForgetPassword, options);

            if (response.status === 200) {
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Password reset successfully!'
                })
                setTimeout(() => {
                    history.push(`${URLRoutes.Login}`)
                }, 5000);
            } else if (response.status === 404) {
                errorText = await response.text();
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: errorText ? errorText : 'Error'
                })
                snackbarAutoHide();
            }
        } catch (ex) {
            console.log('ex', ex);
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        }

    }

    const helperValidateEmail = (email: string) => {
        let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length > 0 && !regEmail.test(email)) {
            return 'Invalid Email Address';
        }
    }

    useEffect(() => {
        if (!errorMsgEmail) {
            setRegisterButton(true);
        } else {
            setRegisterButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 3000);
    }

    return (<div className={styles.login_page_wrapper}>
        <div className={styles.login_page_left_section}>
            <div className={styles.header_title}>
                <span className="icon icon_beoexport_medium"></span>
            </div>
            <div className={styles.title_section}>
                <div className={styles.greeting_msg}>Nice to see you again</div>
                <div className={styles.greeting_title}>WELCOME BACK</div>
                <hr />
                <div className={styles.secondary_text}>
                </div>
            </div>
        </div>
        <div className={styles.login_page_right_section}>
            <div className={styles.go_back_section_login}>
                <ArrowBackIosIcon className={styles.icon_back} />
                <Link to={URLRoutes.Login} className={styles.back_to_login}>Login</Link>
            </div>
            <h1 className={styles.login_title}>RESET PASSWORD</h1>
            <h4 className={styles.login_subtitle}>Enter your email.</h4>
            <form noValidate autoComplete="off">
                <TextField autoComplete='off' name='email' variant="outlined" placeholder={"Email"} type='text' helperText={errorMsgEmail} error={errorInputEmail} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 90 }} />
                <button onClick={(e) => resetPass(e)} className={` ${styles.button} ${!registerButton ? styles.disabled : ''}`}>RESET</button>
            </form>
        </div>
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>)
}

export default ForgetPassword;