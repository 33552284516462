import React, { useEffect, useState, MouseEvent } from 'react';
import styles from './AdminsTable.module.scss'
import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableSortLabel } from '@material-ui/core';
import { PageSizeParameters } from '../../../enums/Enums';
import { useHistory, useLocation } from 'react-router-dom';
import { URLRoutes } from '../../../enums/Routes';
import { IHeaderCells, IAdmins } from '../../../interface/Table';
import BeoExportTableContainer from '../BeoExportTableContainer/BeoExportTableContainer';
import { LocalStorage } from '../../../enums/LocalStorage';
import AdminService from '../../../services/Admin';
import { formatDate } from '../../../helpers/DateTimeHelper';
import Loading from '../../Loading/Loading';

const AdminsTable = (props: { filterParameters: any }) => {
    type Order = 'asc' | 'desc';
    const [data, seAdminsData] = useState<IAdmins[]>([]);
    const history = useHistory();
    const location = useLocation();
    const queryString = require('query-string');
    const [page, setPage] = useState<number>(queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>(queryString.parse(location.search).order_by ? queryString.parse(location.search).order_by.split(' ')[1] : 'desc');
    const [orderBy, setOrderBy] = useState<string>(queryString.parse(location.search).order_by ? queryString.parse(location.search).order_by.split(' ')[0] : 'USERID');
    const [loading, setLoading] = useState<boolean>(false);

    const headCells = [
        { key: '', label: '' },
        { key: 'USERID', label: 'Member number' },
        { key: 'KOR_IME', label: 'Full name' },
        { key: 'KOR_EMAIL', label: 'Email' },
        { key: 'ADRESA', label: 'Address' },
        { key: 'GRAD', label: 'City' },
        { key: 'POST_BR', label: 'Postcode' },
        { key: 'DRZAVA_NAZIV', label: 'State' },
        { key: 'DAT_RODJ', label: 'DOB' },
        { key: 'OVLAST', label: 'Authorization' },
        { key: '', label: '' },
    ];

    useEffect(() => {
        getAllAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderBy, page, order]);

    useEffect(() => {
        // it needs to set page to 0 on change filter
        // if page is already 0, call getUserTransactions()
        // else set page to 0 and useEffect of the page will call getUserTransactions()
        page === 0 ? getAllAdmins() : setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterParameters])

    const getAllAdmins = async () => {
        setLoading(true);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;
        const pageNum = page + 1;
        const sortByParam = order;

        AdminService.getAllAdmins(token, pageNum, orderBy, sortByParam, props.filterParameters).then((response) => {
            const { data } = response;
            seAdminsData(data ? data.result : []);
            setCount(data.totalCount);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    }

    const redirectToView = (item: any) => {
        history.push({
            pathname: `${URLRoutes.UserView}`,
            state: { data: item }
        });
    }

    const formatAdminIcon = (authorization: number | string) => {
        switch (authorization) {
            case 3:
                return <div className={`${styles.green_dot}`}></div>
            case 2:
                return <div className={`${styles.red_dot}`}></div>
            case 0:
                return <div className={`${styles.gray_dot}`}></div>
        }
    }

    const createSortHandler = (property?: string) => (event: MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');

        if (property) {
            setOrderBy(property);
            resetFilter();
        }
    };


    const resetFilter = () => {
        setPage(0);
        setCount(0);
    }


    return <div className={styles.admins_table_wrapper}>
        <div className={styles.admins_table_header}>
            <div className={styles.recent_admins}>Admin users</div>
            <TablePagination component="div" count={count} page={count ? page : 0} onPageChange={(event: MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage++)}
                labelRowsPerPage='' rowsPerPage={PageSizeParameters.PageSizeTen} rowsPerPageOptions={[]} />
        </div>
        <BeoExportTableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {headCells.map((cell: IHeaderCells, index) => (
                            <TableCell key={index}>
                                {cell.key ?
                                    <TableSortLabel
                                        active={orderBy === cell.key}
                                        direction={orderBy === cell.key ? order : 'asc'}
                                        onClick={createSortHandler(cell.key)}>
                                        {cell.label}
                                    </TableSortLabel> : cell.label
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{formatAdminIcon(item.OVLAST)}</TableCell>
                            <TableCell>{item.USERID}</TableCell>
                            <TableCell style={{ textTransform: 'capitalize' }}>{item.KOR_IME + " " + item.KOR_PREZIME}</TableCell>
                            <TableCell>{item.KOR_EMAIL}</TableCell>
                            <TableCell>{item.ADRESA}</TableCell>
                            <TableCell>{item.GRAD}</TableCell>
                            <TableCell>{item.POST_BR}</TableCell>
                            <TableCell>{item.DRZAVA_NAZIV}</TableCell>
                            <TableCell>{formatDate(item.DAT_RODJ)}</TableCell>
                            <TableCell>{item.OVLAST}</TableCell>
                            <TableCell>
                                <span className={`icon_svg icon_arrow`} onClick={() => redirectToView(item)}></span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </BeoExportTableContainer>
        <Loading loading={loading} />
    </div>
}

export default AdminsTable;