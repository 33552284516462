import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../helpers/DateTimeHelper';
import styles from './TransactionView.module.scss';

const TransactionViewClient = () => {
    const history = useHistory();
    const [recipientData, setRecipientData] = useState<any>();

    useEffect(() => {
        const recData: any = history.location.state;
        if (recData) {
            setRecipientData(recData.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location]);


    return <div className={styles.transaction_view_wrapper}>
        <div className={styles.menu_wrapper}></div>
        <div className={styles.go_back_button} onClick={history.goBack}> <span className={`icon_svg icon_arrow ${styles.span_icon}`}></span> Go Back </div>
        <div className={styles.header_section}>
            <div className={styles.title}>Transaction No. {recipientData && recipientData.ID ? recipientData.ID : ''}</div>
            <div className={styles.subtitle}>Below are expanded transaction details.</div>
        </div>
        <div className={styles.information_wrapper}>
            <div className={styles.infomation_section}>
                <div className={styles.left_section}>
                    <div className={styles.section_title}>Recipient Information</div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Recipient Name</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value_cap}>{recipientData && recipientData.RECIPEINT ? recipientData.RECIPEINT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Address</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value_cap}>{recipientData && recipientData.ADDRESS ? recipientData.ADDRESS : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>City</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value_cap}>{recipientData && recipientData.CITY ? recipientData.CITY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Post code</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.POST_CODE ? recipientData.POST_CODE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Country</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value_cap}>{recipientData && recipientData.COUNTRY ? recipientData.COUNTRY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Telephone number</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.TELEPHONE_NUMBER ? recipientData.TELEPHONE_NUMBER : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Date of Birth</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.DATE_OF_BIRTH ? formatDate(recipientData.DATE_OF_BIRTH) : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Email</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.EMAIL ? recipientData.EMAIL : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Mobile Number</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.MOBILE_NUMBER ? recipientData.MOBILE_NUMBER : '/'}</div>
                    </div>
                </div>
                <div className={styles.border_line}></div>
                <div className={styles.right_section}>
                    <div className={`${styles.section_title_second} ${styles.section_title}`}>Transaction Information</div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Payout amount</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.PAYOUT_AMOUNT ? recipientData.PAYOUT_AMOUNT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>AUD/EUR</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>1 AUD = {recipientData && recipientData.EXCHANGE_RATE_CURRENCY ? recipientData.EXCHANGE_RATE_CURRENCY : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Paying amount</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.PAYING_AMOUNT ? recipientData.PAYING_AMOUNT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Fee</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.FREE ? recipientData.FREE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Discount</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.DISCOUNT ? recipientData.DISCOUNT : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Other costs</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.OTHER_COSTS ? recipientData.OTHER_COSTS : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Transaction date</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.DATUM_TRANSFERA ? formatDate(recipientData.DATUM_TRANSFERA) : '/'}</div>
                    </div>
                    <div className={`${styles.text_row}`}>
                        <div className={styles.title}>Total Payable</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.TOTAL_PAYABLE ? recipientData.TOTAL_PAYABLE : '/'}</div>
                    </div>
                    <div className={`${styles.text_row} ${styles.with_background}`}>
                        <div className={styles.title}>Date of payment</div>
                        <div className={styles.colon}>:</div>
                        <div className={styles.value}>{recipientData && recipientData.DATUM_ISPLATE && recipientData.DATUM_ISPLATE !== '0000-00-00' ? formatDate(recipientData.DATUM_ISPLATE) : '/'}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default TransactionViewClient;