export enum URLRoutes {
    Empty = '',
    Login = '/login',
    Registration = '/register',
    MoneyTransfer = '/money-transfer',
    TransactionListing = '/transaction-listing',
    TransactionItem = '/transaction-item',
    AboutUs = '/about-us',
    Help = '/help',
    Profile = '/profile',
    Admins = '/admin/admins',
    CommissionFee = '/admin/commission-fee',
    ExchangeRate = '/admin/exchange-rate',
    PaymentOptions = '/admin/payment-options',
    PayoutOptions = '/admin/payout-options',
    PurposeOfTransfer = '/admin/purpose-of-transfer',
    Transactions = '/admin/transactions',
    Users = '/admin/users',
    UserItem = '/user-item',
    ForgetPassword = '/forgot-password',
    SetProfileData = '/set-profile-data',
    UserView = '/user-item',
    TransactionView = '/transaction-view'
}