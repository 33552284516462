import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './AddRecipient.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { Box, makeStyles, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { INewRecipientData, IRecipientData } from '../../interface/TransferMoney';
import moment from 'moment';
import { Country, States } from '../../enums/Enums';
import MaskedInput from 'react-maskedinput';
import { formatDate } from '../../helpers/DateTimeHelper';
import HelpIcon from '@material-ui/icons/Help';
import UserTransactions from '../../services/UserTransactions';
import { LocalStorage } from '../../enums/LocalStorage';
import DateRangeRounded from '@material-ui/icons/DateRangeRounded';

const AddRecipient = (props: INewRecipientData) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        p: 4,
    };
    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#04539B',
            color: '#FFFFFF',
            fontSize: "14px"
        }
    }));
    const classes = useStyles();
    const initialData = {
        first_name: '',
        middle_name: '',
        last_name: '',
        date_of_birth: '',
        email: '',
        mobile_number: '',
        home_number: '',
        country: '',
        city: '',
        address: '',
        post_code: '',
        account_number: '',
        swift: '',
        bank: '',
        NAPOMENA: '',
        PORUKA: '',
        SLIKA_INSTRUKCIJE_BANKE: ''
    }
    const [recipientData, setRecipientData] = useState<IRecipientData>(initialData);
    const [saveButton, setSaveButton] = useState<boolean>(false);

    // First row of inputs
    const [errorInputFirstName, setErrorInputFirstName] = useState(false);
    const [errorInputMiddleName, setErrorInputMiddleName] = useState(false);
    const [errorInputLastName, setErrorInputLastName] = useState(false);
    const [errorDateOfBirth, setErrorInputDateOfBirthDate] = useState(false);
    // Second row of inputs
    const [errorInputEmail, setErrorInputEmail] = useState(false);
    const [errorInputMobilePhone, setErrorInputMobilePhone] = useState(false);
    const [errorInputHomePhone, setErrorInputHomePhone] = useState(false);
    // Third row of inputs
    const [errorInputCountry, setErrorInputCountry] = useState(false);
    const [errorInputCity, setErrorInputCity] = useState(false);
    const [errorInputAddress, setErrorInputAddress] = useState(false);
    const [errorInputPostCode, setErrorInputPostCode] = useState(false);
    // Fourth row of inputs
    const [errorInputIBAN, setErrorInputIBAN] = useState(false);
    const [errorInputSWIFT, setErrorInputSWIFT] = useState(false);
    const [errorInputBank, setErrorInputBank] = useState(false);
    // Fifth row of inputs
    const [errorInputMsg, setErrorInputMsg] = useState(false);
    const [errorInputNote, setErrorInputNote] = useState(false);


    // First row of inputs
    const [errorMsgFirstName, setErrorMsgFirstName] = useState<string | undefined>('');
    const [errorMsgMiddleName, setErrorMsgMiddleName] = useState<string | undefined>('');
    const [errorMsgLastName, setErrorMsgLastName] = useState<string | undefined>('');
    const [errorMsgDateOfBirth, setErrorMsgDateOfBirth] = useState<string | undefined>('');
    // Second row of inputs
    const [errorMsgEmail, setErrorMsgEmail] = useState<string | undefined>('');
    const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState<string | undefined>('');
    const [errorMsgHomeNumber, setErrorMsgHomeNumber] = useState<string | undefined>('');
    // Third row of inputs
    const [errorMsgCountry, setErrorMsgCountry] = useState<string | undefined>('');
    const [errorMsgCity, setErrorMsgCity] = useState<string | undefined>('');
    const [errorMsgAddress, setErrorMsgAddress] = useState<string | undefined>('');
    const [errorMsgPostCode, setErrorMsgPostCode] = useState<string | undefined>('');
    // Fourth row of inputs
    const [errorMsgIBAN, setErrorMsgIBAN] = useState<string | undefined>('');
    const [errorMsgSWIFT, setErrorMsgSWIFT] = useState<string | undefined>('');
    const [errorMsgBank, setErrorMsgBank] = useState<string | undefined>('');
    // Fifth row of inputs
    const [errorMsg, setErrorMsg] = useState<string | undefined>('');
    const [errorMsgNote, setErrorMsgNote] = useState<string | undefined>('');

    const [country, setCountry] = useState<any>();
    const [balkanCountry, setBalkanCountry] = useState<any>();

    useEffect(() => {
        getBalkanCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBalkanCountry = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        UserTransactions.getBalkanCountry(token).then((response: { data: any; }) => {
            const { data } = response;
            setBalkanCountry(data);
        }, (error: any) => {
            console.log('error', error);
        })
    }

    const handleChange = (event: ChangeEvent<{ name?: string; value: any }>) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof recipientData;

        switch (name) {
            // First row of inputs
            case 'first_name': {
                setErrorMsgFirstName(helperValidateOnlyLetters(value))
                setErrorInputFirstName(helperValidateOnlyLetters(value) ? true : false);
                break;
            }
            case 'middle_name': {
                setErrorMsgMiddleName(helperValidateOnlyLetters(value))
                setErrorInputMiddleName(helperValidateOnlyLetters(value) ? true : false);
                break;
            }
            case 'last_name': {
                setErrorMsgLastName(helperValidateOnlyLetters(value))
                setErrorInputLastName(helperValidateOnlyLetters(value) ? true : false);
                break;
            }

            case 'date_of_birth': {
                // setErrorMsgDateOfBirth(helperValidateDate(value));
                //setErrorInputDateOfBirthDate(helperValidateDate(value) ? true : false);
                // value = moment(new Date(value)).format('YYYY-MM-DD');
                // for (let index = 0; index < value.length; index++) {
                //     if (value[index] !== '_' && index === 9) {
                //         const dateValue = compareDate(value);
                //         value = moment(dateValue).format('YYYY-MM-DD');
                //     }
                // }
                //  value = moment(value).format('YYYY-MM-DD');
                break;
            }

            // Second row of inputs
            case 'email': {
                setErrorMsgEmail(helperValidateEmail(value))
                setErrorInputEmail(helperValidateEmail(value) ? true : false);
                break;
            }

            case 'mobile_number': {
                setErrorMsgPhoneNumber(helperValidateOnlyNumbers(value))
                setErrorInputMobilePhone(helperValidateOnlyNumbers(value) ? true : false);
                break;
            }

            case 'home_number': {
                setErrorMsgHomeNumber(helperValidateOnlyNumbers(value))
                setErrorInputHomePhone(helperValidateOnlyNumbers(value) ? true : false);
                break;
            }

            // Third row of inputs
            case 'country': {
                if (value === States.None) {
                    setErrorInputCountry(true);
                    setErrorMsgCountry('Please select Country!');
                } else {
                    setCountry(value);
                    setErrorInputCountry(false);
                    setErrorMsgCountry('');
                }
                break;
            }

            case 'city': {
                setErrorMsgCity(helperValidateOnlyLetters(value))
                setErrorInputCity(helperValidateOnlyLetters(value) ? true : false);
                break;
            }

            case 'address': {
                setErrorMsgAddress(helperValidateAddress(value))
                setErrorInputAddress(helperValidateAddress(value) ? true : false);
                break;
            }

            case 'post_code': {
                setErrorMsgPostCode(helperValidateOnlyNumbers(value))
                setErrorInputPostCode(helperValidateOnlyNumbers(value) ? true : false);
                break;
            }

            // Fourth row of inputs
            case 'account_number': {
                setErrorMsgIBAN(helperValidateNumberAndLetters(value))
                setErrorInputIBAN(helperValidateNumberAndLetters(value) ? true : false);
                break;
            }
            case 'swift': {
                setErrorMsgSWIFT(helperValidateNumberAndLetters(value))
                setErrorInputSWIFT(helperValidateNumberAndLetters(value) ? true : false);
                break;
            }

            case 'bank': {
                setErrorMsgBank(helperValidateNumberAndLetters(value))
                setErrorInputBank(helperValidateNumberAndLetters(value) ? true : false);
                break;
            }
            case 'SLIKA_INSTRUKCIJE_BANKE': {

                break;
            }

            // Fifth row of inputs
            case 'PORUKA': {
                setErrorMsg(helperValidateNumberAndLetters(value))
                setErrorInputMsg(helperValidateNumberAndLetters(value) ? true : false);
                break;
            }

            case 'NAPOMENA': {
                setErrorMsgNote(helperValidateNumberAndLetters(value))
                setErrorInputNote(helperValidateNumberAndLetters(value) ? true : false);
                break;
            }

        }

        setRecipientData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const compareDate = (str: string) => {
        // str1 format should be dd/mm/yyyy. Separator can be anything e.g. / or -. It wont effect
        const dt1 = parseInt(str.substring(0, 2));
        const mon1 = parseInt(str.substring(3, 5));
        const yr1 = parseInt(str.substring(6, 10));
        const date1 = new Date(yr1, mon1 - 1, dt1);
        return date1;
    }


    const helperValidateOnlyLetters = (input: string) => {
        if (input.replace(/^[A-Za-z.]+$/, '').length > 0) return 'Only letters allowed!';
    }

    const helperValidateOnlyNumbers = (input: string) => {
        if (input.replace(/^[0-9.]+$/, '').length > 0) return 'Only numbers allowed!';
    }

    const helperValidateEmail = (email: string) => {
        let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length > 0 && !regEmail.test(email)) {
            return 'Invalid Email Address';
        }
    }

    const helperValidateAddress = (input: string) => {
        if (input.replace(/^[a-z.A-Z0-9 \s,'-]*$/, '').length > 0) return 'Only letters and numbers allowed!';
    }

    const helperValidateNumberAndLetters = (input: string) => {
        if (input.replace(/^[a-z0-9. ]+$/i, '').length > 0) return 'Only letters and numbers allowed!';
    }

    const saveRecipient = () => {
        recipientData.country = recipientData.country.SIFRA;
        if (props.saveNewRecipientData) {
            props.saveNewRecipientData(recipientData);
            setSaveButton(false);
        }
    }

    useEffect(() => {
        if (isEmptyPropertie(recipientData.first_name) && isEmptyPropertie(recipientData.last_name) && isEmptyPropertie(recipientData.address) && isEmptyPropertie(recipientData.city) && isEmptyPropertie(String(recipientData.country.SIFRA)) && isEmptyPropertie(recipientData.post_code) && isEmptyPropertie(recipientData.country)) {
            setSaveButton(false);
        } else if (!isEmptyPropertie(recipientData.first_name) && !isEmptyPropertie(recipientData.last_name) && !isEmptyPropertie(recipientData.address) && !isEmptyPropertie(recipientData.city) && !isEmptyPropertie(String(recipientData.country.SIFRA)) && !isEmptyPropertie(recipientData.post_code) && !isEmptyPropertie(recipientData.country)) {
            setSaveButton(true);
        } else {
            setSaveButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipientData]);

    const isEmptyPropertie = (value: string) => {
        return value.length === 0 || value === '' ? true : false;
    }

    return <Box sx={style}>
        <div className={styles.add_recipient_container}>
            <CloseIcon className={styles.close_icon} onClick={props.close} />
            <div className={styles.title_container}>
                <div>
                    <div className={styles.title}>Add Recipient.</div>
                    <div className={styles.subtitle}>Enter recipient information. *Fields marked in red are mandatory.</div>
                </div>
            </div>

            <div className={styles.inputs_section_title}>Recipient Personal Information</div>

            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.input_label}>First Name*</div>
                    <TextField autoComplete='off' name='first_name' value={recipientData.first_name || ''} helperText={errorMsgFirstName} error={errorInputFirstName} className={`${styles.input_field_red_border}`} placeholder={'First Name'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Middle Name</div>
                    <TextField autoComplete='off' name='middle_name' value={recipientData?.middle_name || ''} helperText={errorMsgMiddleName} error={errorInputMiddleName} className={styles.input_field} placeholder={'Middle Name'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Last Name*</div>
                    <TextField autoComplete='off' name='last_name' value={recipientData?.last_name || ''} helperText={errorMsgLastName} error={errorInputLastName} className={styles.input_field_red_border} placeholder={'Last Name'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Date of Birth</div>
                    <div className={styles.daterange_wrapper}>
                        <DateRangeRounded className={styles.daterange_icon} />
                        <MaskedInput mask="11/11/1111" value={recipientData?.date_of_birth || ''} className={styles.input_field_masked} name="date_of_birth" placeholder="dd/mm/yyyy" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                        <div className={styles.error_msg_date}>{errorDateOfBirth ? errorMsgDateOfBirth : ''}</div>
                    </div>

                </div>
            </div>

            <div className={`${styles.autocomplete_container} ${styles.padding_bottom}`}>
                <div>
                    <div className={styles.input_label}>Email</div>
                    <TextField autoComplete='off' name='email' value={recipientData?.email || ''} helperText={errorMsgEmail} error={errorInputEmail} className={styles.input_field} placeholder={'Email'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 90 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Mobile Number</div>
                    <TextField autoComplete='off' name='mobile_number' value={recipientData?.mobile_number || ''} helperText={errorMsgPhoneNumber} error={errorInputMobilePhone} className={styles.input_field} placeholder={'Mobile Number'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 30 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Home Number</div>
                    <TextField autoComplete='off' name='home_number' value={recipientData?.home_number || ''} helperText={errorMsgHomeNumber} error={errorInputHomePhone} className={styles.input_field} placeholder={'Home Number'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 30 }} />
                </div>
            </div>

            <div className={styles.inputs_section_title}>Recipient Location</div>

            <div className={`${styles.autocomplete_container} ${styles.padding_bottom}`}>
                <div className={styles.type_section}>
                    <div className={styles.input_label}>Country*</div>
                    {/* <TextField name='country' value={recipientData?.country || ''} helperText={errorMsgCountry} error={errorInputCountry} className={styles.input_field_red_border} placeholder={'Country'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} /> */}
                    <TextField name='country' variant="outlined" select value={country ? country : Country.None} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}>
                        <MenuItem selected={true} value={Country.None} aria-label="None" className={styles.jobs_filter_status}>None</MenuItem>
                        {balkanCountry ? balkanCountry.map((item: any, index: any) => (
                            <MenuItem key={index} className={`status_option ${item.cssClass} ${styles.jobs_filter_status}`} value={item}>{item.NAZIV}</MenuItem>
                        )) : null
                        }
                    </TextField>
                </div>
                <div>
                    <div className={styles.input_label}>City*</div>
                    <TextField autoComplete='off' name='city' value={recipientData?.city || ''} helperText={errorMsgCity} error={errorInputCity} className={styles.input_field_red_border} placeholder={'City'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 30 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Address*</div>
                    <TextField autoComplete='off' name='address' value={recipientData?.address || ''} helperText={errorMsgAddress} error={errorInputAddress} className={styles.input_field_red_border} placeholder={'Address'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 30 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Postcode*</div>
                    <TextField autoComplete='off' name='post_code' value={recipientData?.post_code || ''} helperText={errorMsgPostCode} error={errorInputPostCode} className={styles.input_field_red_border} placeholder={'Postcode'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                </div>
            </div>

            <div className={styles.inputs_section_title}>Bank Information</div>

            <div className={`${styles.autocomplete_container} ${styles.padding_bottom}`}>
                <div>
                    <div className={styles.input_label}><div>Account Number(IBAN)</div>
                        <div>
                            <Tooltip classes={{ tooltip: classes.customTooltip }} title="This field is not mandatory only if you select payout method - Home Delivery or Over the Counter - CASH">
                                <HelpIcon className={styles.help_icon} />
                            </Tooltip>
                        </div>
                    </div>
                    <TextField autoComplete='off' name='account_number' value={recipientData?.account_number || ''} helperText={errorMsgIBAN} error={errorInputIBAN} className={styles.input_field} placeholder={'Account Number(IBAN)'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 60 }} />
                </div>
                <div>
                    <div className={styles.input_label}><div>SWIFT</div>
                        <div>
                            <Tooltip classes={{ tooltip: classes.customTooltip }} title="This field is not mandatory only if you select payout method - Home Delivery or Over the Counter - CASH">
                                <HelpIcon className={styles.help_icon} />
                            </Tooltip>
                        </div>
                    </div>
                    <TextField autoComplete='off' name='swift' value={recipientData?.swift || ''} helperText={errorMsgSWIFT} error={errorInputSWIFT} className={styles.input_field} placeholder={'Swift'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 30 }} />
                </div>
                <div>
                    <div className={styles.input_label}><div>Bank name</div>
                        <div>
                            <Tooltip classes={{ tooltip: classes.customTooltip }} title="This field is not mandatory only if you select payout method - Home Delivery or Over the Counter - CASH">
                                <HelpIcon className={styles.help_icon} />
                            </Tooltip>
                        </div>
                    </div>
                    <TextField autoComplete='off' name='bank' value={recipientData?.bank || ''} helperText={errorMsgBank} error={errorInputBank} className={styles.input_field} placeholder={'Bank name'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 30 }} />
                </div>
            </div>

            <div className={styles.inputs_section_title}>Additional Notes</div>

            <div className={`${styles.autocomplete_container} ${styles.padding_bottom}`}>
                <div>
                    <div className={styles.input_label}>Message to Beo-Export</div>
                    <TextField autoComplete='off' name='PORUKA' value={recipientData?.PORUKA || ''} helperText={errorMsg} error={errorInputMsg} className={styles.input_field} placeholder={'Message to Beo-Export'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} />
                </div>
                <div>
                    <div className={styles.input_label}>Notes</div>
                    <TextField autoComplete='off' name='NAPOMENA' value={recipientData?.NAPOMENA || ''} helperText={errorMsgNote} error={errorInputNote} className={styles.input_field} placeholder={'Notes'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} />
                </div>
            </div>

            <div className={styles.buttons_container}>
                <button className={`${styles.save_button} ${!saveButton ? styles.disabled : ''}`} onClick={saveRecipient} >SAVE</button>
            </div>
        </div>
    </Box>

}

export default AddRecipient;