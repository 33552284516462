import React, { ChangeEvent, useState, MouseEvent, useEffect } from 'react'
import styles from './Login.module.scss';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { URLRoutes } from "../../enums/Routes";
import { URL } from "../../enums/Url";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ISnackbar } from '../../interface/Snackbar';
import SnackbarAlert from '../../components/SnackbarAlert/SnackbarAlert';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface LocationState {
    from: {
        pathname: string;
    };
}

const Login = () => {
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const location = useLocation<LocationState>();
    const { from } = location.state || { from: { pathname: "/" } };
    const [showPassword, setShowPassword] = useState(false);

    const handleClick = () => {
        setShowPassword(prev => !prev);
    }


    const login = async (e: MouseEvent) => {
        e.preventDefault();

        const logInData = {
            login: username,
            password: password
        };

        let errorText;

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(logInData)
        };

        try {
            const response = await fetch(URL.Login, options);

            if (response.status === 200) {
                const data = await response.json();
                localStorage.setItem('auth', JSON.stringify(data));
                history.replace(from ?? "");
                if (data.ov === 3) {
                    history.push(URLRoutes.Transactions);
                } else {
                    if (data.validateProfile) {
                        history.push(URLRoutes.MoneyTransfer);
                    } else {
                        history.push(URLRoutes.SetProfileData);
                    }

                }

            } else if (response.status === 401) {
                errorText = await response.text();
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: errorText ? errorText : 'Incorrect email and/or Password!'
                })
                snackbarAutoHide();
                history.push(URLRoutes.Login);
            }

        } catch (error) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        }
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }


    useEffect(() => {
        let adminLevelString = localStorage.getItem('auth');
        let currentRole = adminLevelString && adminLevelString.length > 0 ? JSON.parse(adminLevelString) : '';
        if (adminLevelString && (location.pathname === URLRoutes.Login || location.pathname === URLRoutes.Empty || location.pathname === '/') && currentRole.ov === 1) {
            history.push({
                pathname: `${URLRoutes.MoneyTransfer}`
            });
        }

        if (adminLevelString && (location.pathname === URLRoutes.Login || location.pathname === URLRoutes.Empty || location.pathname === '/') && currentRole.ov === 3) {
            history.push({
                pathname: `${URLRoutes.Transactions}`
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (<div className={styles.login_page_wrapper}>
        <div className={styles.login_page_left_section}>
            <div className={styles.header_title}>
                <span className="icon icon_beoexport_medium"></span>
            </div>
            <div className={styles.title_section}>
                <div className={styles.greeting_msg}>Nice to see you again</div>
                <div className={styles.greeting_title}>WELCOME BACK</div>
                <hr />
                <div className={styles.secondary_text}>
                </div>
            </div>
        </div>
        <div className={styles.login_page_right_section}>
            <h1 className={styles.login_title}>LOGIN</h1>
            <h4 className={styles.login_subtitle}>Sign in to your account</h4>
            <form noValidate autoComplete="off">
                <TextField autoComplete='off' variant="outlined" placeholder={"Email"} type='text' onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)} inputProps={{ maxLength: 90 }} />
                <TextField autoComplete='off' variant="outlined" placeholder={'Password'} type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClick}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} inputProps={{ maxLength: 64 }} />
                <div className={styles.links_section}>
                    <div onClick={() => window.open("https://beoexport.com.au/wp-content/uploads/general/BEOE001_11232_020_Privacy-Policy_2018-07-11.pdf")} className={styles.forgot_password}>{'Privacy Policy'}</div>
                    <Link to={URLRoutes.ForgetPassword} className={styles.privacy_policy}>{'Forgot Password'}</Link>
                </div>
                <button onClick={(e) => login(e)} className={styles.button}>LOGIN</button>
                <div className={styles.register_section}>
                    <p className={styles.register_question}>Don't have an account yet?</p>
                    <Link to={URLRoutes.Registration} className={styles.register_link}>Join Beo-Export</Link>
                </div>
            </form>
        </div>
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>)
}

export default Login;